
.footer{

 margin-top: 40px;
  background: black;
  height: auto;
  width: 100vw;
  padding-top: 40px;
  color: white;
}
.footer-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.footer-content h3{
  font-size: 2.1rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 3rem;
}
.footer-content p{
  max-width: 500px;
  margin: 10px auto;
  line-height: 28px;
  font-size: 14px;
  color: #cacdd2;
}
.socials{
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 3rem 0;
}
.socials li{
  margin: 0 10px;
}
.socials a{
  text-decoration: none;
  color: #fff;
  border: 1.1px solid white;
  padding: 5px;

  border-radius: 50%;

}
.socials a i{
  font-size: 1.1rem;
  width: 20px;


  transition: color .4s ease;

}
.socials a:hover i{
  color: aqua;
}

.footer-bottom{
  background: #000;
  width: 100vw;
  padding: 20px;
padding-bottom: 40px;
  text-align: center;
}
.footer-bottom p{
float: left;
  font-size: 14px;
  word-spacing: 2px;
  text-transform: capitalize;
}
.footer-bottom p a{
color:#44bae8;
font-size: 16px;
text-decoration: none;
}
.footer-bottom span{
  text-transform: uppercase;
  opacity: .4;
  font-weight: 200;
}

.fluidGrid {
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
} 

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 1rem;    /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1rem;            /* Footer height */
}
